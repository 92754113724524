.gs_control-group {
    // padding: 0px 20px;
}

.form-group {
    margin-bottom: 18px;
}

.inputborderbox {
    border-radius: 6px;
    //  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #666666;
    background-color: #ffffff;
    height: 48px;
    input {
        border: none;
        margin: 0px;
    }
    ::-webkit-input-placeholder {
        color: #d1d1d1;
    }

    :-moz-placeholder {
        /* Firefox 18- */
        color: #d1d1d1;
    }

    ::-moz-placeholder {
        /* Firefox 19+ */
        color: #d1d1d1;
    }

    :-ms-input-placeholder {
        color: #d1d1d1;
    }

    /* Text inputs */
    input[type="text"],
    input[type="password"],
    input[type="email"],
    input[type="url"],
    input[type="tel"],
    input[type="number"],
    input[type="search"],
    textarea {
        background-color: transparent;
        border: none;
        outline: none;
        height: 30px;
        width: 93%;
        font-size: 16px;
        margin: 0 0 0px 0;
        padding: 0;
        box-shadow: none;
        box-sizing: content-box;
        transition: all 0.1s;
        color: #666666;
    }

    textarea {
        height: unset;
    }

    input[type="text"]:focus:not([readonly]) + label,
    input[type="tel"]:focus:not([readonly]) + label,
    textarea:focus:not([readonly]) + label {
        color: #24272c;
    }

    .gsc_mtl_field {
        position: relative;
        margin-top: 3px !important;
        padding: 4px 1rem;
    }

    .gsc_mtl_field.col label {
        left: 0.75rem;
    }

    .gsc_mtl_field label {
        color: #333333;
        position: absolute;
        top: 6px;
        font-size: 16px;
        cursor: text;
        transition: 0.2s ease-out;
    }

    .gsc_mtl_field label.active {
        -webkit-transform: translateY(-100%);
        font-size: 14px;
        transform: translateY(-100%);
        background: WHITE;
        padding: 0 5px;
        transition: 0.2s ease-in;
    }

    /*To removing yellow background color on input autocomplete */
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-transition: "color 9999s ease-out, background-color white 9999s ease-out";
        -webkit-transition-delay: 9999s;
        -webkit-box-shadow: 0 0 0 500px white inset;
    }
}

.input-field {
    input {
        width: 100%;
        padding: 0px 20px;
        padding-top: 6px;
        background-color: transparent;
        border: none;
        outline: none;
        height: 30px;
        width: 93%;
        font-size: 16px;
        margin: 0 0 0px 0;
        padding: 0;
        box-shadow: none;
        box-sizing: content-box;
        transition: all 0.1s;
        color: #666666;
    }
    .icon {
        position: absolute;
        top: 8px;
        right: 11px;
    }
    label {
        left: 12px !important;
    }
    label.leftside {
        left: 1.75rem !important;
    }
}

.inputFocus {
    border: 1px solid #007c92 !important;
}

.otp-field {
    width: 58px;
    height: 48px;
    text-align: center;
    padding: 10px;
    margin-right: 10px;
    border: 1px solid;
    border-radius: 6px;
    font-size: 25px;
}

@media only screen and (max-width: 767px) {
    .otp-field {
        width: 48px;
        height: 44px;
    }
}
