// Contain Css of service request
.help_Support_tobi {
    width: 64px;
    height: 64px;
    padding: 10px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    border-radius: 50% 50%;
    margin-bottom: 4px;
}
.faq {
    color: #333;
}

.p-t-125 {
    padding-top: 100px;
}

// .help_Support_layout {
// padding-left: 60px;
// padding-right: 60px;
// padding-bottom: 40px;
// }

.help_Support_title {
    font-family: $font-family-regular;
    font-size: 36px;
    font-weight: bold;
}

.help_Support_text {
    font-family: $font-family-light;
    font-size: 28px;
    font-weight: 400;
}

.help_Support_anchor {
    font-size: 32px;
    line-height: 40px;
    cursor: pointer;
}

.m-t-125 {
    margin-top: 140px;
}

.callBack-title {
    font-family: $font-family-regular;
    font-size: 34px;
    line-height: 48px;
    font-weight: bold;
    margin-bottom: 20px;
}

@media only screen and (max-device-width: 1024px) {
    .help_Support_layout {
        padding-left: 28px;
        padding-right: 28px;
        padding-bottom: 18px;
    }
}

@media only screen and (max-device-width: 768px) {
    .help_Support_layout {
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 10px;
    }

    .help_Support_title {
        font-size: 24px;
    }

    .help_Support_text {
        font-size: 20px;
    }

    .help_Support_anchor {
        font-size: 20px;
        line-height: 30px;
    }

    .m-t-125 {
        margin-top: 80px;
    }

    .help_Support_tobi {
        width: 50px;
        height: 50px;
        padding: 8px;
        margin-bottom: 4px;
    }

    .ava {
        height: 35px;
        width: 35px;
    }

    .callBack-title {
        font-size: 20px;
        margin-bottom: 15px;
    }
}
// @media (min-width:961px)
// }

/////////////////////////MOBILE VIEW CSS///////////////////////////////////////

// @media only screen and (max-width: 769px) {
//     .help_Support_layout {
//         padding-left: 0px;
//         padding-right: 0px;
//         padding-bottom: 40px;
//     }
// }
