.billing_details_b2b {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
}
.response-detail {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  margin-top: 110px;
  margin-bottom: 30px;

  h1 {
    opacity: 0.8;
  }
}
.rotate-back {
  transform: rotateY(203deg);
}

.alert-error-bg {
  h4 {
    color: #ed1b24;
  }
  .icon-container ._icon {
    background: #ed1b24d4 !important;
    color: #fff;
  }
}

.alert-success-bg {
  h4 {
    color: #149d14cc;
  }
  .icon-container ._icon {
    background: #149d14cc !important;
    color: #fff;
  }
}

.alert-box {
  color: #333;
  border-radius: 4px;
  margin-bottom: 10px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  padding: 10px 20px;
  align-items: center;
  display: flex;
  .icon-container {
    border-right: 2px solid #d6cece38;
    width: 100;
    padding: 9px;
    margin-right: 12px;

    ._icon {
      background: #ffffff42;
      padding: 10px 15px;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
      font-size: 15px;
      border-radius: 50%;
      margin-right: 5px;
    }
  }
  h4 {
    font-size: 19px;
    margin-bottom: 4px;
    font-weight: 600;
  }
  p {
    font-size: 14px;
    margin: 0;
    opacity: 0.8;
  }
}
.fail-icon {
}

.pass-icon {
  color: white;
  background: green;
  padding: 3px;
  font-size: 13px;
  border-radius: 50%;
  margin-right: 5px;
}
.mssidn_plan_details_b2b {
  img {
    margin-right: 16px;
  }
}

.table_padding {
  padding: 28px;
}

.text26px {
  color: #333333;
  font-size: 26px;
  line-height: 28px;
  font-family: Vodafone-Regular;
  margin: 0px;
}

// .serviceHistory {
//   margin-top: 128px;
// }

.main_component_container {
  margin: 53px 75px;
}

.back-button {
  padding: 8px 27px 8px 18px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-size: 17px;
  font-weight: 600;
  opacity: 0.8;
  cursor: pointer;
}

.back-button-sr {
  background: white;
  margin-bottom: 10px;
  width: 100px;
}

@media only screen and (max-width: $widthabove-tablet) {
  .main_component_container {
    margin: 26px;
  }
  // .serviceHistory {
  //   margin-top: 91px;
  // }
}

////////////////////////////////////////////>>>> mobile <<<<<<<///////
@media only screen and (max-width: $widthbelo-mobile) {
  .main_component_container {
    margin: 16px;
  }
  // .serviceHistory {
  //   margin-top: 79px;
  // }
}
