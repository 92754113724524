.postpaid-login {
    padding: 0 10px;
    .otp-error {
        padding-left: 53px;
    }

    .timer-container {
        width: 75%;
    }
    .login-title {
        font-family: $font-family-regular;
        font-size: 22px;
        color: #5f5f5f;
        margin-bottom: 15px;
        margin-top: 6px;
        font-weight: 600;
    }

    form {
        // text-align: center;
        .otpContainer {
            display: flex;
            flex-direction: column;
            margin-bottom: 15px;
            align-items: center;
            position: relative;

            .eye-icon {
                width: 24px;
                position: absolute;
                top: 14px;
                right: 14px;
            }
        }

        .resend-otp {
            text-align: right;
            font-size: 18px;
            line-height: 22px;
            text-decoration-line: underline;
            color: #333333;
        }
        .resend-otp-timer {
            text-align: right;
            font-size: 18px;
            line-height: 22px;
            color: #333333;
        }
    }

    .new-request {
        font-family: $font-family-regular;
        font-size: $f-s-20;
        line-height: 22px;
        text-decoration-line: underline;
        color: #333333;
    }
    .bottom-area {
        height: 224px;
        img {
            height: auto;
            width: 100%;
            border-radius: 5px;
        }
    }
    // .bottom-area {
    //     background: linear-gradient(45deg, #820000 0%, #e60000 100%);
    //     box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    //     border-radius: 6px;
    //     display: flex;
    //     padding: 10px;
    //     justify-content: space-between;

    //     .play-button {
    //         height: 140px;
    //     }

    //     .heading {
    //         font-size: $f-s-24;
    //         line-height: 30px;
    //         color: #ffffff;
    //         font-weight: 100;
    //         margin-bottom: 65px;
    //     }
    //     .text {
    //         font-family: $font-family-regular;
    //         font-size: 18px;
    //         line-height: 24px;
    //         color: #ffffff;
    //     }
    // }
}

.mobile-number-container {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    .prefix {
        width: 17%;
        .form-group .inputborderbox .gsc_mtl_field {
            padding: 4px 4px;
            padding-left: 10px;
        }
    }
    .mobile-number {
        width: 80%;
    }
}

.new-connection {
    .title {
        font-size: $f-s-20;
        color: #333333;
        margin-bottom: 22px;
        font-weight: 600;
    }
}
.login-drawer-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100vw;
    height: 100vh;
    opacity: 0.5;
    background-position: right;
    background-color: #333333 !important;
    overflow: hidden;
    transition: opacity 0.15s linear;
}
.bg-picture {
    position: fixed;
    top: 39px;
    left: auto;
    z-index: 1049;
    /*left: 259px;
    width: 100vw;
    background-position: right;*/
    width: 100%;
    height: 100vh;
    background-color: #333333 !important;
    overflow: hidden;
    transition: opacity 0.15s linear;
    background: url("../../Assets/images/login/login-backdrop.png") right top no-repeat;
    background-size: cover;
}
.fade {
    transition: opacity 0.15s linear;
}

.login-drawer {
    width: 500px;
    z-index: 2000;
    top: 73px;
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    background-color: #fff;
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
    transition: opacity 0.15s linear;

    .login-drawer-header {
        position: relative;
        padding: 6px;

        .login-drawer-close-button {
            cursor: pointer;
            height: 26px;
            right: -32px;
            position: absolute;
            top: 12px;
            color: #847d7d;
            background: transparent url("../../Assets/images/close_white.svg") center/1em auto no-repeat;
        }
    }
    .login-drawer-body {
        flex-grow: 1;
        padding: 0rem 30px 1rem 30px;
        overflow-y: auto;
    }
}

//tab
@media only screen and (max-width: $widthabove-tablet) {
    .postpaid-login {
        form {
            // text-align: center;
            .otpContainer {
                .eye-icon {
                    right: 16px;
                }
            }
        }

        .timer-container {
            width: 80%;
        }
    }
    .login-drawer {
        width: 456px;
        top: 64px;
    }
    .response-comp {
        img {
            height: 83.24px;
            width: 88.48px;
        }
    }
    .mobile-number-container {
        display: inline-flex;
        justify-content: space-between;
        width: 100%;
        .prefix {
            width: 20%;
            .form-group .inputborderbox .gsc_mtl_field {
                padding: 4px 4px;
            }
        }
        .mobile-number {
            width: 78%;
        }
    }
}

////////////////////////////////////////////>>>> mobile <<<<<<<///////
@media only screen and (max-width: $widthbelo-mobile) {
    .postpaid-login {
        .bottom-area {
            img {
                height: auto;
            }
        }
        .otp-error {
            padding-left: 0px;
        }
        form {
            // text-align: center;
            .otpContainer {
                .eye-icon {
                    right: 16px;
                }
            }
        }

        .timer-container {
            width: 100%;
        }
    }

    .login-drawer {
        width: 100%;
        top: 54px;
    }
    .login-drawer-body {
        padding: 5px 10px 1rem 10px !important;
    }

    .response-comp {
        img {
            height: 74.99px;
            width: 79.16px;
        }
    }
    .mobile-number-container {
        display: inline-flex;
        justify-content: space-between;
        width: 100%;
        .prefix {
            width: 26%;
            .form-group .inputborderbox .gsc_mtl_field {
                padding: 4px 2px 4px 3px;
            }
        }
        .mobile-number {
            width: 73%;
        }
    }
    .bottom-area {
        img {
            height: auto;
            width: 100%;
        }
    }
}
