/********************
    Typography scss
********************/
@font-face {
  font-family: Vodafone-Regular;
  src: url("../fonts/Vodafone-Regular.ttf");
}

@font-face {
  font-family: VodafoneExBd;
  src: url("../fonts/VodafoneExBd.ttf");
}

@font-face {
  font-family: VodafoneLt;
  src: url("../fonts/VodafoneLt.ttf");
}

@font-face {
  font-family: VodafoneRg-Bold;
  src: url("../fonts/VodafoneRg-Bold.ttf");
}
