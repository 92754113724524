.response-comp {
  img {
    height: 51.73px;
    width: 6.41px;
  }
}

//text field

.text-area {
  height: 125px !important;
}

.alert-message {
  display: flex;
  border: 1px solid #e60000;
  border-radius: 0px 6px 6px 0px;
  margin-bottom: 1rem;

  .icon-area {
    display: flex;
    background: #e60000;
    padding: 0px 16px;
    justify-content: center;
    text-align: center;
  }

  .message-area {
    padding: 8px;
    font-family: $font-family-regular;
    font-size: 16px;
    line-height: 22px;
    color: #333333;
  }
}

//preloader
.preloader-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  p {
    font-size: 13px;
  }
}

.spinner-border {
  width: 3rem;
  height: 3rem;
}

.loading-screen {
  margin-top: 50%;
}

//drawer

.offcanvas-body {
  padding: 0rem 30px 1rem 30px !important;
}

.Offcanvas-custome {
  width: 500px !important;
  z-index: 2000 !important;
  top: 73px !important;
}

.Offcanvas_custom_header {
  position: relative;
  padding: 6px;

  .btn-close {
    font-size: 26px;
    position: absolute;
    right: -39px;
    top: 2px;
    color: #847d7d;
    background: transparent url("../../Assets/images/close_white.svg") center/1em auto no-repeat;
  }
}

//plan Card comp

.data-planCard {
  border: 2px solid #e4e4e4;
  border-radius: 6px;

  p {
    font-size: 18px;
  }

  & ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      img {
        width: 22px;
        margin-right: 4px;
      }

      margin-bottom: 5px;

      span {
        font-size: 18px;
        color: #575757;
      }
    }
  }

  div {
    font-size: 20px;
    color: #333333;
    font-weight: 600;

    span {
      margin-right: 5px;
      color: #494949;
    }
  }
}

//tab
@media only screen and (max-width: $widthabove-tablet) {
  .Offcanvas-custome {
    width: 456px !important;
    top: 71px !important;
  }

  .response-comp {
    img {
      height: 83.24px;
      width: 88.48px;
    }
  }

  ////////////////////////////////////////////>>>> mobile <<<<<<<///////
  @media only screen and (max-width: $widthbelo-mobile) {
    .Offcanvas-custome {
      width: 90% !important;
      top: 71px !important;
    }

    .offcanvas-body {
      padding: 5px 10px 5px 10px !important;
    }

    // .popover {
    //   top: 3px !important;
    // }
    .response-comp {
      img {
        height: 74.99px;
        width: 79.16px;
      }
    }
  }

  .alert-message {
    .icon-area {
      background: #e60000;
      padding: 0px 20px;
    }
  }

  //preloader
  .preloader-container {
    p {
      font-size: 11px;
    }
  }

  .spinner-border {
    width: 2.2rem;
    height: 2.2rem;
  }

  .response-message {
    h4 {
      font-size: 16px;
      font-weight: 600;
    }

    img {
      width: 50%;
    }
  }
}

.text-justify {
  text-align: justify !important;
}