.billing_section_main_div {
  .pay_button {
    line-height: 28px;
    font-size: 20px;
    padding: 8px 20px 8px 20px;
    width: 145px;
  }
  .pay_button:hover {
    transform: scale(1.1);
  }
}

.bill_breakdown_pay {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    margin-right: 10px;
  }
}

// BILL BREAKDOWN COMPONENT CSS//

.bill_breakdown_main_div {
  .mid_header_part {
    display: none;
  }
  margin-bottom: 30px;
  .header_pay_button {
    padding: 5px 25px;
    font-family: Vodafone-Regular;
    font-size: 20px;
  }

  .bill_breakdown_header {
    padding: 30px;
  }

  .breakdown_card {
    max-width: 610px;
    margin: 0 auto;
    overflow: auto;
    padding: 0;
  }

  .breakdown_card_phone {
    position: relative;
    display: flex;
    flex-direction: row;
    background: #ffffff;

    img {
      height: 32px;
    }

    .card-name {
      position: absolute;
      left: 35px;
      bottom: 16px;
    }

    .card-number {
      position: absolute;
      left: 35px;
      bottom: 0px;
    }
  }

  .float_right {
    float: right;
  }
}

.card-breakdown {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #33333333;
  border-radius: 6px;
  padding: 7px;

  img {
    height: 32px;
  }

  .card-name {
    position: absolute;
    font-size: 14px;
    left: 70px;
    bottom: 19px;
  }

  .card-number {
    font-size: 12px;
    color: #666666;
    position: absolute;
    left: 70px;
    bottom: 0px;
  }
  .exp-date {
    font-size: 13px;
    color: #666666;
    position: absolute;
    bottom: 4px;
    right: 8px;
  }
}

//Transactions HISTORY//
.transaction_history_main_div {
  // margin-bottom: 200px;
  // margin-top: 112px;
  p {
    margin: 0px !important;
  }
  .blueCard {
    border: 1px solid #007c92 !important;
    box-shadow: none !important;
  }
}
//payment

//chart
.chart-card {
  h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 48px;
    color: #333333;
  }
}
.chart-area {
  display: flex;
  min-height: 132px;
  overflow-x: auto;
}
.chart-area .bar-wrapper {
  display: flex;
}
.chart-area .bar-wrapper .chart-bar {
  float: left;
  height: 73px;
  width: 8px;
  margin-right: 25px;
  border-radius: 6px;
}
.chart-area .bar-wrapper .chart-track {
  position: relative;
  width: 8px;
  height: 100%;
  background: #fff;
  /* border: 1px solid #ebebeb; */
  border-radius: 6px;
}
.chart-area .bar-wrapper .chart-fill {
  position: relative;
  height: 50%;
  width: 8px;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  background: #e60000;
}

.bar-text {
  position: absolute;
  bottom: -23px;
  left: -6px;
  font-size: 14px;
  line-height: 18px;
  color: #999999;
}

.bar-display {
  display: block;
  margin-right: 10px;
  margin-top: 13px;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
}

.card-showcase {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #33333333;
  border-radius: 6px;
  padding: 7px;

  img {
    height: 32px;
  }

  .card-name {
    position: absolute;
    font-size: 14px;
    left: 70px;
    bottom: 19px;
  }

  .card-number {
    font-size: 12px;
    color: #666666;
    position: absolute;
    left: 70px;
    bottom: 0px;
  }
  .exp-date {
    font-size: 13px;
    color: #666666;
    position: absolute;
    bottom: 4px;
    right: 8px;
  }
}

.CVV-card-image {
  height: 63px;
  margin-top: 7px;
}

.card-showcase:focus {
  border: 2px solid #007c92;
}

//********NO_BILL_SCREEN

.nobill_screen_main {
  padding: 10px 6px;
  text-align: center;
  img {
    width: 15%;
  }
}

.prepaid_topup_screen {
  background-color: #fff;
  padding: 30px;
  border-radius: 6px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
}

.top_ups_list {
  background-color: #fff;
  padding: 30px;
  border-radius: 6px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
}
/**********************MEDIA QUERY FOR MOBILES*******************************/

@media only screen and (max-width: 480px) {
  .billing_section_main_div {
    padding: 16px !important ;

    .pay_button {
      line-height: 22px;
      font-size: 16px;
      padding: 4px 16px 4px 16px;
      width: 88px;
      height: 44px;
      border-radius: 6px;
    }
  }
  .bill_breakdown_pay {
    flex-direction: column;
    :nth-child(1) {
      margin-bottom: 5px;
    }
    h3 {
      margin-right: 0px;
    }
  }

  .transaction_history_main_div {
    // margin-top: 60px;
    // padding: 16px;
  }

  .bill_breakdown_main_div {
    .bill_breakdown_header {
      padding: 15px;

      .bill_amount {
        padding-right: 2px;
      }
    }
    .breakdown_card {
      padding: 16px;
    }
    .header_pay_button {
      width: 88px;
      height: 35px !important;
      font-size: 16px;
    }
  }

  .nobill_screen_main {
    img {
      width: 34%;
    }
  }
}

.response-bill-break {
  margin-top: 100px !important;
}

.payment {
  text-align: center;

  .message {
    color: #8d8d8d;
    font-size: 24px;
    margin-bottom: 30px;
  }

  .succes_icon img {
    margin-top: 20px;
    width: 90px;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  ul li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    color: #545454;
  }

  .paid {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 14px;
  }

  .amt {
    color: #e60000;
  }

  .transaction {
    padding-bottom: 25px;
  }

  .f-b {
    font-weight: 600;
  }

  .note {
    font-size: 14px;
    color: #ed1b24;
    margin-top: 25px;
  }
}
