//nav bar

.navbar {
    z-index: 2000 !important;
    flex-wrap: unset !important;
    padding: 16px 40px;
}

.navBarContainer_login {
    padding: 15px 0px !important;
}

.navBar-img {
    width: 135px;
}

.nav-items {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .nav-toggle-icon {
        display: none;
    }

    .nav-tems-links {
        display: flex;
        flex-direction: row;
    }
}

.nav-drawer-list {
    margin-top: 40px;
}

.nav-shadow {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
}

.avatar {
    width: 32px;
    height: 32px;
    display: flex;
    overflow: hidden;
    position: relative;
    font-size: 16px;
    align-items: center;
    flex-shrink: 0;
    font-family: "Vodafone-Regular";
    line-height: 22px;
    user-select: none;
    border-radius: 50%;
    justify-content: center;
    background: rgba(0, 124, 146, 1);
    color: #ffffff;
    font-style: normal;
    font-weight: normal;
}

.nav-style {
    font-family: "Vodafone-Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 28px;
    color: rgba(51, 51, 51, 1) !important;
    padding: 0.5rem 1rem;

}

.custom_accordian_footer {
    width: 100%;
    transition: all 600ms ease-in-out;
    border-bottom: 1px solid #666666;
    color: #ffff;

    .accordingHeading {
        padding: 16px 0px 16px 0px;
        font-size: 18px;
    }

    .accordian_content {
        height: 0;
        opacity: 0;
        overflow: hidden;
        transition: all 600ms ease-in-out;
    }

    .content_padding {
        padding-bottom: 16px;
    }

    .accordian_content_show {
        opacity: 1;
        overflow: hidden;
        transition: all 600ms ease-in-out;
    }
}

.footer {
    font-family: "Vodafone-Regular";
    font-style: normal;
    font-weight: normal;
    color: #ffffff;
    font-size: 28px;
    line-height: 34px;
    background: linear-gradient(45deg, #820000 0%, #e60000 100%);
    padding: 20px 16px;
}

.footer-social {
    background: #303233;
    padding: 22px;
    max-width: 100% !important;
}

.footer-social-font {
    font-family: "Vodafone-Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    color: #ffffff;
}

.footer-social-border {
    border-bottom: 1px solid #666666;
}

.vodaPhoneLogo {
    position: relative;
    top: 20px;
}

.footer-nav {
    font-family: "Vodafone-Regular";
    font-style: normal;
    font-weight: normal;
    font-size: $f-s-16;
    line-height: 24px;

    a {
        color: #ffffff;
        text-decoration: none;
        font-size: 14px;
    }

    :hover {
        color: #ed1b24;
    }
}

.accordingHeading {
    text-transform: uppercase;
}

.avaFloating {
    position: fixed;
    top: 75%;
    right: -7px;
    z-index: 1000;
}

.AVA-property {
    height: 70px;
    width: 80px;
}

.myAccountPopover-header {
    display: flex;
    justify-content: space-between;
}

.myAccountPopover-container {
    display: flex;

    .info-container {
        position: relative;
        display: block;
        width: 100%;
        padding-left: 10px;

        p {
            margin-bottom: 0px !important;
        }

        .name {
            position: absolute;
            bottom: 15px;
        }

        .mobile {
            position: absolute;
            top: 11px;
        }
    }
}

.layout-container {
    margin-top: 75px;
}

.popover {
    z-index: 2000;
    top: 12px !important;
    width: 276px;
    left: 4px !important;

    .popover-arrow {
        display: none !important;
    }

    .popover-header {
        background: none !important;
        border-bottom: none !important;
    }
}

.footer-links {
    display: block;

    h5 {
        text-transform: uppercase;
    }
}

.footer-links-acoordian {
    display: none;
}

.logout-icon {
    width: 44px;
}

@media only screen and (max-width: $widthabove-tablet) {
    .navbar {
        padding: 12px 39px;
    }

    .navBarContainer_login {
        padding: 15px 0px !important;
    }

    .layout-container {
        margin-top: 72px;
    }

    .footer-links {
        display: none;
    }

    .footer-links-acoordian {
        display: block;
    }
}

////////////////////////////////////////////>>>> mobile <<<<<<<///////
@media only screen and (max-width: $widthbelo-mobile) {
    .navbar {
        padding: 10px 19px;
    }

    .navBarContainer_login {
        padding: 15px 0px !important;
    }

    .nav-items {
        .nav-toggle-icon {
            display: block;
        }

        .nav-tems-links {
            display: none;
        }
    }

    .layout-container {
        margin-top: 71px;
    }

    .footer-social {
        padding: 20px 16px;
    }

    .footer-links {
        display: none;
    }

    .footer-links-acoordian {
        display: block;
    }

    .logout-icon {
        width: 35px;
    }
}