//  contain all the Css for Dashboard
.font-style {
    font-family: $font-family-regular;
    font-style: normal;
    color: #333333;
}
.dashboard-typo {
    font-weight: bold;
    font-size: 24px;
    line-height: 34px;
}
.dasboard-bill {
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    color: #333333;
}

.dasboard-spend {
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
}

.dasboard-pound {
    font-weight: normal;
    font-size: 64px;
    line-height: 72px;
}

.dasboard-bill-details {
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
}

.dasboard-bill-details-voice {
    margin-left: -6px;
}

.border-bottom {
    border-bottom: 1px solid #ebebeb;
}

.dashboard-addButton {
    width: 100%;
    border: 1px solid #999999 !important;
    box-sizing: border-box;
    border-radius: 6px;
    color: #333333 !important;
}

.font-color {
    color: #eb3434;
}

.usageCard {
    .progress {
        height: 0.5rem;
        .progress-bar {
            background-color: #e60000;
        }
    }
}

.mb-32 {
    margin-bottom: 32px;
}
.mt-21 {
    margin-top: 21px !important;
}
.mb-14 {
    margin-bottom: 14px !important;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.b-sh {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
}

.carousel {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
}

.dashbord-help-support {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    padding: 20px;
    height: 109px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../Assets/images/dashboard/helpSupport.svg");
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}
.text-color {
    color: #ffffff;
}

.cardHeight {
    max-height: 109px;
}

.font-weight-bold {
    font-weight: bold;
}
.font-weight-normal {
    font-weight: normal;
}

.dashboard-product-type {
    line-height: 13px;
    padding: 3px 10px;
}

.dashboard-fiberproduct-card {
    background: #e60000;
    border-radius: 6px 6px 6px 6px;
    max-width: 160px;
}

.dashboard-product-card {
    background: #007c92;
    border-radius: 6px 6px 6px 6px;
    max-width: 160px;
}

.dashboard-product-text {
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
    background: #ebebeb;
    border-radius: 6px;
    min-height: 156px;
}

.dashboard-product-image {
    position: absolute;
}

.dashboard-product-active {
    background: #e60000;
    .dashboard-product-text {
        border: 2px solid #e60000;
    }
}

.dashboard-account {
    margin-top: 40px !important;
    margin-bottom: 10px;
}

.dashboard-account-card {
    max-height: 177px;
}

.dashboard-usage-text-color {
    color: #e60000;
}

.data-unit-used {
    font-size: 26px;
    font-weight: 600;
}

.data-unit-used-break {
    font-size: 20px;
    font-weight: 600;
}
.light-font {
    opacity: 0.7;
}

.card-p-b {
    .card-body {
        padding-bottom: 4px;
    }
}

.dashboard-asistance-text {
    font-size: 20px;
    line-height: 28px;
}

.dashboard-Discover-section {
    img {
        width: 100%;
        height: auto;
        border-radius: 5px;
    }
}

.dashboard-Discover-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background: rgba(51, 51, 51, 0.4);
    mix-blend-mode: normal;
    padding: 20px 30px;
}

.dashbord-help-support-text {
    position: relative;
    top: 6px;
}

.account-margin-bottom {
    margin-bottom: 0px;
}

.error-loader-height {
    min-height: 534px;
    position: relative;
}

.loader-position {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
}

// .loader-position-response {
//     margin-top: 25%;
// }

.accountImagePos {
    position: relative;
    top: -18px;
}

// Carousel Css

.dashboard-carousel {
    min-height: 120px;
    .slide-indicators {
        z-index: 2;
        padding: 0;
        list-style: none;
        .indicators-button {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #cccccc;
            margin-left: 8px;
        }
        .active {
            background-color: #e60000;
        }
    }
    .dashboard-carousel-usage {
        font-size: 38px;
        line-height: 35px;
    }
    .crousel-usage-subtext {
        font-size: 30px;
        line-height: 36px;
    }
    .crousel-usage-sub-subtext {
        font-size: 16px;
        line-height: 22px;
    }
}

.productErrorImage {
    width: 80px;
}
.productErrorHeading {
    font-size: 18px;
}
.productErrorMessage {
    font-size: 14px;
}
.productLoaderErrorCard {
    height: 170px;
}

.errorMobileImg {
    width: 50px;
}

.planDataSection{
    width: 100%;
    cursor: pointer;
}

// @media only screen and (max-device-width: 575px) {

//     .dashboard-mobile-bill{
//         .card-body{
//             padding: 12px;
//         }
//     }
//     // .dashbord-help-support{
//     //     height: 90px;
//     // }
//     .dashboard-mobile-bill-text {
//         font-family: VodafoneRg;
//         font-size: 16px;
//         line-height: 22px;
//     }

//     .dashboard-mobile-bill-amount {
//         font-family: VodafoneLt;
//         font-size: 42px;
//         line-height: 40px;
//     }
// }
@media only screen and (max-device-width: 1200px) {
    #dashboard-post-70 {
        line-height: 16px;
    }
}
@media only screen and (max-device-width: 991px) {
    #dashboard-post-70 {
        font-size: 15px;
        line-height: 16px;
    }
}

@media only screen and (max-device-width: 768px) {
    .dashbord-help-support {
        height: 90px;
    }
    .dashboard-mobile-bill-text {
        font-size: 16px;
    }

    .dashboard-mobile-bill-amount {
        font-size: 40px;
        line-height: 40px;
    }
    .dashbord-help-support-text {
        font-size: 15px;
        line-height: 18px;
    }

    .dashbord-help-support-image {
        width: 24px;
        height: 24px;
    }
    .addons {
        .card-body {
            padding: 12px;
        }
        .mb-32 {
            margin-bottom: 17px;
        }
    }
    .dashboard-mobile-bill {
        .card-body {
            padding: 10px;
        }
    }
    .account-margin-bottom {
        margin-bottom: 20px;
    }
    .dashboard-typo {
        padding-bottom: 0px !important;
        font-size: 22px;
    }
    .dashboard-mobile-bill .card-body {
        min-height: 185px;
    }
}

@media only screen and (max-device-width: 470px) {
    #dashboard-post-70 {
        font-size: 14px;
        line-height: 16px;
    }
    .addons .card-body {
        padding: 12px;
        min-height: 97px;
    }
    .dashboard-mobile-bill .card-body {
        min-height: 203px;
    }

    .dashboard-account {
        margin-top: 30px !important;
        margin-bottom: 6px;
    }
}
