.usage_comp_main_css {
  margin-bottom: 20px;

  .progress {
    height: 6px;
  }

  .usage_padding {
    padding-right: 100px;
    padding-left: 100px;
  }
}

.usage_card_planandUsage {
  .imgTag {
    width: 36px;
  }
}

.changeplan_confirm_p {
  font-size: 18px;
}

.changeplan_confirm {
  p {
    font-size: 16px;
  }

  .form-check-label {
    font-size: 18px;
  }
}

//////////////////////////////////UPGEADE PLAN///////////////////////////////////////////

.upgrage_plan_main {
  padding: 0px;

  .upgrade_plan_body {
    padding: 5px 0px;
    min-height: 330px;
  }
}

@media only screen and (max-width: 768px) {
  .usage_comp_main_css {
    margin-bottom: 20px;

    .progress {
      height: 6px;
    }

    .usage_padding {
      padding-right: 0px;
      padding-left: 0px;
    }
  }

  .usage_card_planandUsage {
    .imgTag {
      width: 20px;
    }
  }

  .upgrage_plan_main {
    padding: 0px;

    .upgrade_plan_body {
      padding: 5px 0px;
    }
  }
}

.planCard {
  // border-right: 1px solid #ebebeb;
  // padding-right: 40px;
}

.planCard-title {
  font-family: $font-family-regular;
  font-size: 30px;
  line-height: 48px;
  margin-bottom: 36px;
}

.planCard-act {
  font-family: $font-family-regular;
  font-size: 20px;
  line-height: 28px;
}

.planCard-act-color {
  color: #009900;
}

.myPlan-title {
  font-family: $font-family-regular;
  font-size: 28px;
  line-height: 34px;
}

@media only screen and (max-device-width: 1024px) {
  .planCard-title {
    font-size: 20px;
    line-height: 40px;
    margin-bottom: 30px;
  }
}

.scroll-gride {
  --gutter: 16px;
  // padding: 12px 0;
  display: grid;
  grid-gap: var(--gutter) 0;
  grid-template-columns: var(--gutter) 1fr var(--gutter);
  align-content: start;
}

.scroll-gride>* {
  grid-column: 2 / -2;
}

.scroll-gride>.full {
  grid-column: 1 / -1;
  overflow-y: hidden;
}

.hs {
  display: grid;
  // grid-gap: calc(var(--gutter) / 2);
  grid-template-columns: 0px repeat(var(--total), calc(215px - var(--gutter) * 2)) 10px;
  grid-template-rows: minmax(150px, 1fr);
  overflow-x: auto;
  scroll-snap-type: x proximity;
  padding-bottom: calc(3 * var(--gutter));
  padding-bottom: 70px;
}

.hs:before,
.hs:after {
  content: "";
}