

@mixin generate($prefix, $property) {
    // List of sizes to generate for each
    // Spacing to multiply the sizes by
    $sizes: 0,1,2,3,4,5,6,7,8,9,10, 11, 12, 13, 14,15,;
    $spacing: 4px;
    
    // Loop through all of the sizes(we use @for rather than @each, as we want access to the index)
    @for $i from 1 through length($sizes) {
      // Get the size for the current index
      $size: nth($sizes, $i);
      
      // Create the rule
      .#{$prefix}-#{$i - 1} {
        #{$property}: $spacing * $size !important;
      }
    }
  }

  @include generate(m-l, margin-left);
  @include generate(m-r, margin-right);
  @include generate(m-t, margin-top);
  @include generate(m-b, margin-bottom);
  @include generate(f-s, font-size);