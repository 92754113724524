.totalMobileNoText {
    font-size: 24px;
    font-family: Vodafone-Regular;
}

.totalMobileNoPending {
    font-family: Vodafone-Regular;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    text-align: justify;
    color: #e60000;
}

.myProduct-forward {
    width: 62px;
    height: 62px;
    display: flex;
    overflow: hidden;
    position: relative;
    font-size: 16px;
    align-items: center;
    flex-shrink: 0;
    font-family: "Vodafone-Regular";
    line-height: 22px;
    user-select: none;
    border-radius: 50%;
    justify-content: center;
    background: #ffffff;
    color: #ffffff;
    font-style: normal;
    font-weight: normal;
    margin-top: 55px;
}

.sr_history {
    margin-top: 74px;
}

.Sr-font {
    font-family: Vodafone-Regular;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
}

.Sr-font-com {
    font-family: Vodafone-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
}
.sr-image {
    height: 33px;
}

.sr-image-container {
    width: 70px;
}
.sr-history-status {
    font-family: Vodafone-Regular;
    width: 126px;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    border: 1px solid #999999;
    box-sizing: border-box;
    border-radius: 6px;
}
.sr-update {
    position: relative;
    left: 3.5em;
}
.Sr-viewMore {
    font-family: Vodafone-Regular;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #e60000;
    font-weight: bold;
}

.sr-title {
    margin-right: 5em;
}

.sr-arror {
    width: 12px;
    height: 17px;
    position: relative;
    top: 8px;
}

.sr-title-color {
    color: #666666;
}

.sr-search {
    .form-select {
        background-color: #f4f4f4;
        width: 200px;
        border-radius: 18px;
    }
}
