@import "~bootstrap/scss/bootstrap";

@import "./global";

@import "./custom.scss";

@import "./override";

@import "./typography";

@import "./reusableSASS";

@import "../../components/layout/layout";

@import "./responsive.scss";

//module
@import "../../components/login/login";

@import "../../components/dashboard/sass/dashboard";

@import "../../components/mybills/sass/billing";

@import "../../components/help&support/sass/helpSupport";

@import "../../components/MyPlan&Usage/sass/myPlanAndUsageSASS";

@import "../../components/common/common.scss";

@import "../../components/B2B/sass/b2b";

@import "../../components/B2B/dashboard/saas/b2bDashboard";

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
