//tooltip
.tooltip.show {
    opacity: 1;
}
.carousel-inner {
    border-radius: 6px;
}

.tooltip-inner {
    background: white;
    color: black;
    border: 1px solid #29282866;
}

//modal
.modal {
    z-index: 10000;
}

.modal-header {
    border-bottom: none !important;
}
.modal-backdrop {
    background-color: #333333 !important;
}

.modal-body {
    padding: 0px 1rem 1rem 1rem;
}

/////////////////form/////////////

.form-select {
    // background: white url('../images/close_white.svg');
    background-position: right 5px top 50%;
    height: 50px;
    border-radius: 6px;
    border: solid 1px;
}

.form-control:focus {
    box-shadow: none;
}

.invalid-feedback {
    display: block;
}

.input-form {
    position: relative;
}

.icon {
    position: absolute;
    top: 8px;
    right: 11px;
}

.invalid-feedback {
    margin-top: 0;
}

.input-form img svg {
    fill: #333333;
}

.form-check-label {
    font-size: 14px;
    color: #666666;
}

.form-check-input {
    width: 17px !important;
    height: 17px !important;
    margin-right: 10px !important;
    margin-top: 6px !important;
    cursor: pointer;
}

.form-check-input:checked {
    background-color: #007c92 !important;
    border-color: #007c92 !important;
    box-shadow: none;
}

//button

.btn-outline-secondary {
    border-color: #333333;
}

.btn-outline-secondary:hover {
    background-color: transparent;
}

button:disabled {
    background-color: #666666 !important;
    border-color: #666666 !important;
}

.assistant-tooltip {
    display: none !important;
}

// .assistant-bt-bot-icon {
//     bottom: 2px !important;
// }

.table > :not(:first-child) {
    border-top: none !important;
}

.table .selection-cell input {
    width: 16px;
    height: 16px;
    cursor: pointer;
}
