/********************
    Global Css
********************/
/***********************************
            Global Reset             
***********************************/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none !important;
}

/* Initialisation media query width */

$widthbelo-mobile: 767px;
$widthabove-tablet: 1200px;

/* Initialisation of font size and font family */
$f-s-12: 12px;
$f-s-14: 14px;
$f-s-16: 16px;
$f-s-18: 18px;
$f-s-20: 20px;
$f-s-24: 24px;
$f-s-26: 26px;
$f-s-28: 28px;
$f-s-30: 30px;
$f-s-32: 32px;
$f-s-34: 34px;
$f-s-36: 36px;
$f-s-38: 38px;
$f-s-40: 40px;

$margin-zero: 0px;
$padding-zero: 0px;

$font-family-regular: Vodafone-Regular;
$font-family-light: VodafoneLt;
$font-family-bold: VodafoneRg-Bold;
$font-family-extbold: VodafoneExBd;

$font-weight4: 400;
$font-weight6: 600;
$font-weight8: 800;
$font-bold: bold;

html body {
  margin: $margin-zero;
  font-family: $font-family-regular;
  font-size: $f-s-14;
  font-weight: $font-weight4;
}
body p {
  font-size: $f-s-16;
}

body h1 {
  font-weight: $font-bold;
}

input:focus {
  outline-color: #007c92;
}

.error {
  color: red;
  font-size: $f-s-16;
  width: 100%;
}

.error-border {
  border-color: red !important;
}

.error-color {
  color: red !important;
}
.heightUnset {
  height: unset !important;
}

.chatbot_rydot iframe {
  height: 400px !important;
  width: 450px !important;
}
