$primary: #e60000;

$theme-colors: (
  "primary": $primary,
);

$body-bg: #f4f4f4;
$body-color: #333333;
$font-size-base: 1.125rem; // Assumes the browser default, typically `16px`

//offCAnvas

.main-bolts {
  padding: 5px;
}
.bolts-cost {
  font-family: $font-family-regular;
  font-size: $f-s-20;
  line-height: $f-s-24;
  margin-bottom: 42px;
  /* identical to box height, or 120% */

  color: #333333;
}
.bolts-heading {
  font-size: 20px;
  line-height: 24px;
}
.bolts-euro {
  font-family: $font-family-regular;
  font-size: $f-s-24;
  line-height: 30px;
  /* identical to box height, or 125% */
  color: #333333;
  margin-bottom: 10px;
}
.bolts-text {
  // display: flex;
  font-family: $font-family-regular;
  font-size: $f-s-18;
  line-height: 22px;
  padding: 5px;

  /* identical to box height, or 122% */

  color: #333333;
}
.bolts-number {
  font-family: $font-family-regular;
  font-size: $f-s-18;
  line-height: $f-s-18;
  /* identical to box height, or 100% */

  color: #333333;
}
.bolts-bolton {
  font-family: $font-family-regular;
  font-size: $f-s-24;
  line-height: $f-s-30;
  font-weight: 900;
  margin-bottom: 40px;
  color: #333333;
}

.login_tiels {
  padding: 18px !important;
  border-radius: unset !important;
}

.bolts-local {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  padding: 24px 20px;
  justify-content: space-around;
  display: flex;
  margin-bottom: 16px;
  align-items: center;
  color: #333333;

  h5 {
    margin-bottom: 0px !important;
  }
}
.bolts-addData{
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  padding: 24px 20px;
  justify-content: space-around;
  display: flex;
  margin-bottom: 16px;
  align-items: center;
  gap: 2rem;
}
.success-close-button {
  margin-top: 5rem;
}

.disabled-text {
  opacity: 0.5;
  cursor: not-allowed !important;
}
.usage-text {
  color: $primary !important;
  margin-left: 10px;
  font-size: 13px;
}
//container custome size

// @media (min-width: 1200px) {
//   .container {
//     max-width: 1190px !important;
//   }
// }

// @media (min-width: 860px) {
//   .container {
//     max-width: 830px !important;
//   }
// }
// @media (min-width: 992px) {
//   .container {
//     max-width: 980px !important;
//   }
// }

// @media (min-width: 768px) {
//   .container {
//     max-width: 754px !important;
//   }
// }

// @media only screen and (max-width: $widthabove-tablet) {
//   .container {
//     max-width: 830px !important;
//   }
//   //body
// }

////////////////////////////////////////////>>>> mobile <<<<<<<///////
@media only screen and (max-width: $widthbelo-mobile) {
}

/* make the customizations */
@import "./genrator";
/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

// Required
@import "~bootstrap/scss/functions";

// Required
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

// Bootstrap and its default variables

// Optional Bootstrap components here
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
